 <template>
  <div>
    <Navbar></Navbar>
    <b-container class="py-5">
      <b-row>
        <b-col>
          <div style="display: flex; align-items: center; margin-bottom: -30px;">
                  <h2 style="font-size: 30px; font-weight: normal; color: #333333;">关于我们</h2>
                  <i style="font-size: 18px; margin-left: 0px; margin-bottom: 15px; font-style: normal; color: #555;">/About Us</i>
                </div>
        </b-col>
      </b-row>

      <b-row class="my-4">
        <b-col>
          <p class=" abouttext" style=" text-align: justify;">
            中泽教育成立于2015年，凭借与香港联通国际的紧密合作，在欧美STEAM课程基础上自主研发适合国内儿童的创客教育体系，通过社会化的学习和实践，
            让孩子们跨越极限，创造精彩。我们以“STEAM教育+创客空间”为核心，秉承“培养未来创造者”的使命，
            专注于3-16岁少年儿童的科技创造力教育，开设科创教育空间，自主研发科创教育产品与课程，
            围绕学习设计软件、激光雕刻、编程等内容板块，形成独特的教育内容研发生态与产品闭环。让孩子们在真实的项目中获得适应未来的核心能力!
            我们的愿景是成为国际科创教育的倡导者，我们提供的不仅是课程，创客环境，更是一种全新的学习方式，我们相信每个孩子都是未来的创新者，
            让孩子们像科学家一样思考探究，像工程师一样动手实践，像设计师一样创意飞扬，像创客一样学习智造。让他们在探索中发现自我，在实践中成就未来。
          </p>
        </b-col>
      </b-row>

      <b-row>
        <b-col md="6"
               class="text-center">
          <img src="about-us.jpg"
               class="img-fluid rounded  imgd"
               alt="图片1">
        </b-col>
        <b-col md="6"
               class="text-center">
          <img src="about-us2.jpg"
               class="img-fluid rounded imgd"
               alt="图片2">
        </b-col>
      </b-row>
       <!-- 联系我们 - 全宽背景 -->
    <section class="contact-us py-5 " style="margin-top: 30px;">
      <!-- 内容限制在1200px -->
      <div class="content-wrapper">
        <div class="container">
          <h2 class=" text-center mb-4"
              style="font-size: 30px; font-weight: bold; color: #333;">
            联系我们
          </h2>
          <p class="text-muted"
             style="margin-top: -15px; color: #555;">Contanct Us</p>
          <p class="text-muted yhua"
             style="display: none;">右滑更多联系方式 -> </p>
          <div class="row  contacta">

            <!-- 右侧地图与联系方式部分 -->
            <div class="col-lg-5 col-12 text-center">
              <!-- 地图图片 -->
              <img src="map.png"
                   class="img-fluid mb-3  rounded"
                   alt="地图"
                   style="max-width: 100%; border: 1px solid #ddd;">

              <!-- 联系信息 -->
              <div class="contact-info mt-4 text-left bg-white p-4  rounded">
                <p style="font-size: 20px; color: #333; font-weight: bold; margin-bottom: 15px;">联系方式</p>
                <p style="font-size: 16px; color: #333;">
                  <img src="dianhua.svg"
                       alt="电话"
                       width="24"
                       style="margin-right: 10px;">
                  销售热线: 18379276683 | 服务热线: 400 968 0998
                </p>
                <p style="font-size: 16px; color: #333;">
                  <img src="email.svg"
                       alt="邮箱"
                       width="24"
                       style="margin-right: 10px; margin-bottom: 10px;">
                  邮箱: sam@zz-steam.com
                </p>
                <p style="font-size: 20px; color: #333; font-weight: bold; margin-top: 20px;">业务中心</p>
                <p style="font-size: 16px; color: #333;">
                  <img src="dizhi.svg"
                       alt="地址"
                       width="24"
                       style="margin-right: 10px;">
                  地址: 广东省深圳市福田区八卦四路22号华晟达大厦A座309
                </p>
              </div>
            </div>
            <!-- 左侧表单部分 -->
            <div class="col-lg-7 col-12 mb-4">
              <b-form @submit.prevent="submitForm"
                      class="bg-white p-4  rounded">
                <div class="row">
                  <!-- 姓名 -->
                  <div class="col-md-6 mb-3">
                    <b-form-group label="姓名*"
                                  label-for="input-name"
                                  label-class="text-left"
                                  style="margin-top: -30px;">
                      <b-form-input id="input-name"
                                    v-model="form.name"
                                    placeholder="请输入姓名"
                                    required
                                    ></b-form-input>
                    </b-form-group>
                  </div>
                  <!-- 电话 -->
                  <div class="col-md-6 mb-3">
                    <b-form-group label="电话*"
                                  label-for="input-phone"
                                  label-class="text-left"
                                  style="margin-top: -30px;">
                      <b-form-input id="input-phone"
                                    v-model="form.phone"
                                    placeholder="请输入手机号"
                                    required></b-form-input>
                    </b-form-group>
                  </div>
                </div>

                <!-- 邮箱 -->
                <b-form-group label="邮箱"
                              label-for="input-email"
                              label-class="text-left" 
                              style="margin-bottom: 20px;">
                  <b-form-input id="input-email"
                                v-model="form.email"
                                type="email"
                                placeholder="请输入邮箱"></b-form-input>
                </b-form-group>

                <!-- 留言 -->
                <b-form-group label="留言"
                              label-for="input-message"
                              label-class="text-left"
                              >
                  <b-form-textarea id="input-message"
                                   v-model="form.message"
                                   placeholder="请输入留言内容"
                                   style="height: 258px;"></b-form-textarea>
                </b-form-group>

                <!-- 提交按钮，美化样式 -->
                <div class="text-center">
                  <b-button type="submit"
                            variant="primary"
                            class="w-100 py-3"
                            style="background: #3567E8; border: none; box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); font-size: 18px;">
                    立即提交
                  </b-button>
                </div>
              </b-form>
            </div>
          </div>
        </div>
      </div>
    </section>
    </b-container>
    <Footer></Footer>
  </div>
</template>

 <script>
import Navbar from '../components/Navbar.vue';
import Footer from '../components/footer.vue';
import axios from 'axios';

export default {
  data() {
    return {
      form: {
        name: '',
        phone: '',
        email: '',
        message: ''
      }
    };
  },
  components: {
    Navbar,
    Footer
  },
  methods: {
    // 验证手机号格式
    validatePhone(phone) {
      const phoneRegex = /^1[3-9]\d{9}$/;
      return phoneRegex.test(phone);
    },

    // 验证邮箱格式
    validateEmail(email) {
      if (!email) return true; // 邮箱非必填
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return emailRegex.test(email);
    },

    // 表单验证
    validateForm() {
      if (!this.form.name.trim()) {
        this.$bvToast.toast('请输入姓名', {
          title: '提示',
          variant: 'warning'
        });
        return false;
      }

      if (!this.validatePhone(this.form.phone)) {
        this.$bvToast.toast('请输入正确的手机号', {
          title: '提示',
          variant: 'warning'
        });
        return false;
      }

      if (this.form.email && !this.validateEmail(this.form.email)) {
        this.$bvToast.toast('请输入正确的邮箱格式', {
          title: '提示',
          variant: 'warning'
        });
        return false;
      }

      return true;
    },

    // 提交表单
    async submitForm() {
      try {
        // 表单验证
        if (!this.validateForm()) {
          return;
        }

        // 准备提交数据
        const payload = {
          name: this.form.name,
          phone: this.form.phone,
          mail: this.form.email,
          content: this.form.message,
          state: 0
        };

        // 发送请求
        const response = await axios.post('/api/average/save', payload);
        
        if (response.data) {
          // 提交成功
          this.$bvToast.toast('提交成功，我们会尽快与您联系！', {
            title: '成功',
            variant: 'success'
          });

          // 重置表单
          this.resetForm();
        }
      } catch (error) {
        console.error('提交失败:', error);
        this.$bvToast.toast('提交失败，请稍后重试', {
          title: '错误',
          variant: 'danger'
        });
      }
    },

    // 重置表单
    resetForm() {
      this.form = {
        name: '',
        phone: '',
        email: '',
        message: ''
      };
    }
  }
};
</script>

<style scoped>
h1 {
  font-size: 36px;
  font-weight: bold;
}
.imgd{
  width: 100%;
  height: 400px !important;
}
p {
  font-size: 18px;
  line-height: 1.8;
}

.img-fluid {
  max-width: 100%;
  height: auto;
}
@media (max-width: 768px) {
 .abouttext{
  font-size: 14px;
 }

}
/* 基础样式 */
.contact-us {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important;
}

.content-wrapper {
  max-width: 1200px;
  margin: 0 auto;
}

/* 标题样式 */
h2 {
  font-size: 30px;
  font-weight: bold;
  color: #333;
  text-align: center;
  margin-bottom: 1.5rem;
}

.text-muted {
  color: #555 !important;
  margin-top: -15px;
}

/* 地图样式 */
.img-fluid {
  max-width: 100%;
  border: 1px solid #ddd;
  border-radius: 0.25rem;
  margin-bottom: 1rem;
}

/* 联系信息样式 */
.contact-info {
  margin-top: 1.5rem;
  text-align: left;
  background-color: white;
  padding: 1.5rem;
  border-radius: 0.25rem;
}

.contact-info p {
  font-size: 16px;
  color: #333;
}

.contact-info p:first-child {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 15px;
}

.contact-info img {
  margin-right: 10px;
  width: 24px;
}

/* 表单样式 */
.b-form {
  background-color: white;
  padding: 1.5rem;
  border-radius: 0.25rem;
}

.form-group label {
  text-align: left;
}

.form-group {
  margin-bottom: 20px;
}

/* 姓名和电话输入框的特殊样式 */
.form-group[label="姓名*"],
.form-group[label="电话*"] {
  margin-top: -30px;
}

/* 留言文本框样式 */
textarea {
  height: 258px !important;
}

/* 提交按钮样式 */
.btn-primary {
  width: 100%;
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
  background-color: #3567E8 !important;
  border: none !important;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  font-size: 18px;
}

/* 响应式布局 */
@media (max-width: 992px) {
  .yhua {
    display: block !important;
  }
}
</style>