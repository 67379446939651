<template>
  <div class="data-page">
    <Navbar />
    
    <div class="data-content">
      <div v-if="isLoading" class="loading-wrapper">
        <b-spinner variant="primary" label="Loading..."></b-spinner>
      </div>
      
      <img 
        :src="imageSrc"
        alt="数据展示" 
        class="data-image"
        :class="{ 'image-loaded': !isLoading }"
        @load="handleImageLoaded"
        loading="lazy"
      >
    </div>

    <Footer/>
  </div>
</template>

<script>
import Navbar from '@/components/Navbar.vue'
import Footer from '@/components/footer.vue'

export default {
  name: 'DataPage',
  components: {
    Navbar,
    Footer
  },
  data() {
    return {
      isLoading: true,
      imageSrc: '/product.png'
    }
  },
  methods: {
    handleImageLoaded() {
      this.isLoading = false
    },
    preloadImage() {
      const img = new Image()
      img.src = this.imageSrc
      img.onload = this.handleImageLoaded
    }
  },
  mounted() {
    this.preloadImage()
  }
}
</script>

<style scoped>
.data-page {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.data-content {
  flex: 1;
  width: 100%;
  position: relative;
  padding: 0;
  margin: 0;
}

.loading-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f5f5f5;
}

.data-image {
  width: 100%;
  height: auto;
  margin: 0;
  display: block;
  opacity: 0;
  transition: opacity 0.3s ease;
  object-fit: cover;
}

.image-loaded {
  opacity: 1;
}
</style>