<template>
  <div>
		<Navbar></Navbar>
    <b-container  style="margin:50px auto;">
      <b-row>
        <b-col>
          <b-card style="border:0;">
            <div v-html="newsContent" ></div>
          </b-card>
        </b-col>
      </b-row>
    </b-container>
		<Footer></Footer>
  </div>
</template>

<script>
import Navbar from '../components/Navbar.vue';
import Footer from '../components/footer.vue';
export default {
  props: {
    content: {
      type: String,
      required: true
    }
  },
	components: {
    Navbar,
    Footer
  },
  computed: {
    newsContent() {
      return this.content; // 从路由或父组件获取 HTML 内容
    }
  }
};
</script>

<style scoped>
/* 可以添加额外的样式来调整卡片的大小和对齐 */
.b-card {
  margin: auto; /* 使卡片居中 */
  max-width: 800px; /* 设置最大宽度 */
}
</style>
