<template>
  <div class="nav-container">
    <!-- Logo -->
    <div class="logo-container">
      <img src="logo.png" alt="中泽科教" height="40">
    </div>

    <!-- 汉堡菜单按钮 -->
    <div class="hamburger-menu" @click="isCollapse = !isCollapse">
      <i :class="['el-icon-menu', {'is-active': isCollapse}]"></i>
    </div>

    <!-- 导航菜单 -->
    <div class="menu" :class="{'menu-collapsed': isCollapse}">
      <div class="menu-item">
        <router-link to="/index" class="menu-title">首页</router-link>
      </div>

      <div class="menu-item" @mouseenter="showSubmenu(2)" @mouseleave="hideSubmenu">
        <div class="menu-title">科教产品</div>
        <transition name="smooth-slide">
          <div v-if="activeMenu === 2" class="submenu">
            <router-link to="/product" class="submenu-item">科教产品</router-link>
            <router-link to="/software" class="submenu-item">科教软件</router-link>
            <router-link to="/sec" class="submenu-item">科教课程</router-link>
            <router-link to="/seSpace" class="submenu-item">科教空间</router-link>
          </div>
        </transition>
      </div>

      <div class="menu-item">
        <router-link to="/download" class="menu-title">软件下载</router-link>
      </div>

      <div class="menu-item" @mouseenter="showSubmenu(4)" @mouseleave="hideSubmenu">
        <div class="menu-title">商务合作</div>
        <transition name="smooth-slide">
          <div v-if="activeMenu === 4" class="submenu">
            <router-link to="/business" class="submenu-item">商务合作</router-link>
            <router-link to="/onlineMessage" class="submenu-item">测样申请</router-link>
          </div>
        </transition>
      </div>

      <div class="menu-item">
        <router-link to="/vipServe" class="menu-title">会员服务</router-link>
      </div>

      <div class="menu-item" @mouseenter="showSubmenu(6)" @mouseleave="hideSubmenu">
        <div class="menu-title">关于我们</div>
        <transition name="smooth-slide">
          <div v-if="activeMenu === 6" class="submenu">
            <router-link to="/about" class="submenu-item">关于我们</router-link>
            <router-link to="/news" class="submenu-item">新闻资讯</router-link>
          </div>
        </transition>
      </div>

      <div class="menu-item">
        <router-link to="/onlineStore" class="menu-title">在线商城</router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      activeMenu: null,
      isCollapse: false
    }
  },
  methods: {
    showSubmenu(index) {
      this.activeMenu = index
    },
    hideSubmenu() {
      this.activeMenu = null
    }
  }
}
</script>

<style scoped>
.nav-container {
  display: flex;
  align-items: center;
  background-color: #007BFF;
  padding: 0;
}

.logo-container {
  padding: 10px 20px;
  display: flex;
  align-items: center;
}

.menu {
  display: flex;
  margin-left: auto;
}

.menu-item {
  position: relative;
  padding: 0 30px;
  height: 60px;
  display: flex;
  align-items: center;
  cursor: pointer;
  z-index: 1;
}

.menu-title {
  color: #ffffff;
  font-size: 16px;
  text-decoration: none;
  white-space: nowrap;
}

.menu-item:hover .menu-title {
  color: #00FCFF;
}

.submenu {
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  background-color: #ffffff;
  width: fit-content;
  min-width: 100%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  z-index: 10;
}

.submenu-item {
  display: block;
  padding: 12px 20px;
  color: #000000;
  text-decoration: none;
  text-align: center;
  font-size: 14px;
  white-space: nowrap;
}

.submenu-item:hover {
  background-color: #007BFF;
  color: #fff;
}

.hamburger-menu {
  display: none;
  font-size: 24px;
  color: #fff;
  padding: 15px;
  cursor: pointer;
}

/* 动画效果 */
.smooth-slide-enter-active, .smooth-slide-leave-active {
  transition: all 0.3s ease;
  transform-origin: top center;
  opacity: 1;
  transform: scaleY(1) translateX(-50%);
}

.smooth-slide-enter {
  opacity: 0;
  transform: scaleY(0) translateX(-50%);
}

.smooth-slide-leave-to {
  opacity: 0;
  transform: scaleY(0) translateX(-50%);
}

/* 最后一个菜单项的特殊处理 */
.menu-item:last-child .submenu {
  right: 0;
  left: auto;
  transform: none;
}

.menu-item:last-child .smooth-slide-enter-active,
.menu-item:last-child .smooth-slide-leave-active {
  transform-origin: top right;
  transform: scaleY(1);
}

.menu-item:last-child .smooth-slide-enter,
.menu-item:last-child .smooth-slide-leave-to {
  transform: scaleY(0);
}

/* 移动端样式 */
@media screen and (max-width: 992px) {
  .nav-container {
    position: relative;
    z-index: 1000;
  }

  .hamburger-menu {
    display: block;
    margin-left: auto;
    padding: 10px 20px;
  }

  .menu {
    position: absolute;
    top: 60px;
    left: 0;
    right: 0;
    flex-direction: column;
    background-color: #3567E8;
    display: none;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    z-index: 999;
  }

  .menu-collapsed {
    display: flex;
    animation: slideDown 0.3s ease;
  }

  @keyframes slideDown {
    from {
      opacity: 0;
      transform: translateY(-10px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }

  .menu-item {
    width: 100%;
    padding: 0;
    height: auto;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  }

  .menu-item:last-child {
    border-bottom: none;
  }

  .menu-title {
    padding: 15px;
    width: 100%;
    text-align: center;
    font-size: 15px;
  }

  .submenu {
    position: static;
    transform: none;
    width: 100%;
    background-color: rgba(255, 255, 255, 0.1);
    box-shadow: none;
    border-radius: 0;
  }

  .submenu-item {
    padding: 12px;
    color: #fff;
    background-color: transparent;
    border-top: 1px solid rgba(255, 255, 255, 0.1);
    font-size: 14px;
  }

  .submenu-item:hover {
    background-color: rgba(255, 255, 255, 0.2);
  }

  /* 移动端动画效果 */
  .smooth-slide-enter-active,
  .smooth-slide-leave-active {
    transition: all 0.3s ease;
    max-height: 400px;
    opacity: 1;
    transform: none;
  }

  .smooth-slide-enter,
  .smooth-slide-leave-to {
    max-height: 0;
    opacity: 0;
    transform: none;
  }

  /* 移除最后一个菜单项的特殊处理 */
  .menu-item:last-child .submenu,
  .menu-item:last-child .smooth-slide-enter-active,
  .menu-item:last-child .smooth-slide-leave-active,
  .menu-item:last-child .smooth-slide-enter,
  .menu-item:last-child .smooth-slide-leave-to {
    left: 0;
    right: 0;
    transform: none;
  }

  /* 汉堡菜单图标样式 */
  .hamburger-menu .el-icon-menu {
    font-size: 24px;
    transition: transform 0.3s ease;
  }

  .hamburger-menu .el-icon-menu.is-active {
    transform: rotate(90deg);
  }
}

/* 处理 iPad 等中等尺寸设备 */
@media screen and (max-width: 1200px) and (min-width: 993px) {
  .menu-item {
    padding: 0 15px;
  }

  .menu-title {
    font-size: 14px;
  }
}
@media (max-width: 1990px) and (min-width: 1500px) {
  .menu-item {
    margin-left: 60px !important;
  }

}
</style>
