<template>
  <div>
    <Navbar></Navbar>
    <b-container style="margin-top: 50px; cursor: pointer;">
      <div style="margin-bottom: 30px;">
      <h2 style="font-size: 30px; color: #555;font-weight: normal;">新闻资讯</h2>
      <span style="color: #555;">News</span>
    </div>
      <b-row>
        <!-- 遍历新闻列表，调整布局为每行一个新闻项 -->
        <b-col v-for="news in paginatedNews"
               :key="news.id"
               cols="12" >
          <b-card @click="goToNewsDetail(news)"
                  class="mb-4" style="border: 0;">
            <b-row no-gutters>
              <!-- 左边是新闻图片 -->
              <b-col cols="4">
                <b-img :src="news.image"
                       alt="新闻图片"
                       fluid></b-img>
              </b-col>
              <!-- 右边是标题、发布时间、摘要 -->
              <b-col cols="8" style="margin-top: -30px;">
                <b-card-body>
                  <b-card-title class="newsT">{{ news.title }}</b-card-title>
                  <p class="text-muted" style="text-align: left;">{{ news.date }}</p>
                  <b-card-text style="text-align: justify;  color: #555; font-size: 16px;  line-height: 1.8;">{{ news.summary }}</b-card-text>
                </b-card-body>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
      </b-row>
      
      <!-- 添加分页组件 -->
      <b-row class="mt-4 mb-4">
        <b-col class="text-center">
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            align="center"
            class="my-0"
          ></b-pagination>
        </b-col>
      </b-row>
    </b-container>
    <Footer></Footer>
  </div>
</template>

<script>
import axios from 'axios';
import Navbar from '../components/Navbar.vue';
import Footer from '../components/footer.vue';

export default {
  data() {
    return {
      newsList: [],
      currentPage: 1,
      perPage: 3, // 每页显示3条数据
    };
  },
  components: {
    Navbar,
    Footer
  },
  mounted() {
    this.fetchNewsData();
  },
  computed: {
    // 计算总行数
    totalRows() {
      return this.newsList.length;
    },
    
    // 根据当前页码返回分页后的数据
    paginatedNews() {
      const startIndex = (this.currentPage - 1) * this.perPage;
      const endIndex = startIndex + this.perPage;
      return this.newsList.slice(startIndex, endIndex);
    }
  },
  methods: {
    async fetchNewsData() {
      try {
        const response = await axios.get('/api/adminFile/selectAll');
        if (response.data.code === 0) {
          this.newsList = response.data.data
            .map(item => ({
              id: item.id,
              title: item.fileTilte,
              summary: item.fileDice,
              date: `${item.fileCratetime[0]}-${item.fileCratetime[1]}-${item.fileCratetime[2]}`,
              image: `/news/${item.fileCover}`,
              content: item.fileContent,
              timestamp: new Date(`${item.fileCratetime[0]}-${item.fileCratetime[1]}-${item.fileCratetime[2]}`).getTime()
            }))
            .sort((a, b) => b.timestamp - a.timestamp);
        }
      } catch (error) {
        console.error('获取新闻数据失败:', error);
      }
    },
    goToNewsDetail(news) {
      this.$router.push({ name: 'newsDetail', params: { content: news.content } });
    }
  }
};
</script> 

<style scoped>
.text-muted {
  font-size: 0.85rem;
  margin-top: -0.5rem;
 
}
.newsT{
  text-align:justify;
  line-height: 1.8;
}
.newsT:hover {
  color: #3567e8;
}

/* 添加分页样式 */
.pagination {
  justify-content: center;
}

.page-item.active .page-link {
  background-color: #3567e8;
  border-color: #3567e8;
}

.page-link {
  color: #3567e8;
}

.page-link:hover {
  color: #3567e8;
}
</style>
