<template>
  <div>
    <b-form @submit.prevent="onSubmit" style="margin-left: 20%;">
      <b-form-group >
        <b-form-input v-model="account" type="text" placeholder="请输入手机号或邮箱" style="margin-bottom: 20px; margin-top: 20px;width: 235px;"></b-form-input>
      </b-form-group>

      <b-form-group >
        <b-form-input v-model="password" type="password" placeholder="请输入密码" style="margin-bottom: 20px; width: 235px; "></b-form-input>
      </b-form-group>

      <b-form-group >
        <b-form-input v-model="confirmPassword" type="password" placeholder="请确认密码" style="margin-bottom: 20px; width: 235px;"></b-form-input>
      </b-form-group>

      <b-button type="submit" variant="primary" style="width: 235px;margin-left: -85px;">注册</b-button>
    </b-form>
  </div>
</template>

<script>
export default {
  data() {
    return {
      account: '',
      password: '',
      confirmPassword: '',
    };
  },
  methods: {
    onSubmit() {
      if (this.password === this.confirmPassword) {
        console.log('注册信息:', this.account, this.password);
      } else {
        alert('密码不一致');
      }
    },
  },
};
</script>
