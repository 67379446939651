<template>
  <div>
    <Navbar></Navbar>
    <b-container class="d-flex justify-content-center align-items-center vh-100">
      <b-card class="px-0 custom-card "
              header="测样申请"
              header-tag="h2">
        <b-form @submit="onSubmit"
                ref="form" style="margin-left:-150px ;">
          <!-- 公司名称 -->
          <b-form-group label-for="company-name-input"
                        label-cols-sm="2"
                        label-align-sm="right">
            <b-form-input id="company-name-input"
                          v-model="form.companyName"
                          placeholder="请输入公司名称"
                          required></b-form-input>
            <b-form-invalid-feedback v-if="submitted && !companyNameState">公司名称为必填项</b-form-invalid-feedback>
          </b-form-group>
          <!-- 姓名 -->
          <b-form-group label-for="name-input"
                        label-cols-sm="2"
                        label-align-sm="right">
            <b-form-input id="name-input"
                          v-model="form.name"
                          placeholder="请输入您的姓名"
                          required></b-form-input>
            <b-form-invalid-feedback v-if="submitted && !nameState">姓名为必填项</b-form-invalid-feedback>
          </b-form-group>
          <!-- 电话 -->
          <b-form-group label-for="phone-input"
                        label-cols-sm="2"
                        label-align-sm="right">
            <b-form-input id="phone-input"
                          type="tel"
                          v-model="form.phone"
                          placeholder="请输入您的电话"
                          required></b-form-input>
            <b-form-invalid-feedback v-if="submitted && !phoneState">电话为必填项</b-form-invalid-feedback>
          </b-form-group>
          <!-- 邮箱 -->
          <b-form-group label-for="email-input"
                        label-cols-sm="2"
                        label-align-sm="right">
            <b-form-input id="email-input"
                          type="email"
                          v-model="form.email"
                          placeholder="请输入您的邮箱"
                          required></b-form-input>
            <b-form-invalid-feedback v-if="submitted && !emailState">请填写有效的邮箱地址</b-form-invalid-feedback>
          </b-form-group>

          <!-- 公司地址 -->
          <b-form-group label-for="company-address-input"
                        label-cols-sm="2"
                        label-align-sm="right">
            <b-form-input id="company-address-input"
                          v-model="form.companyAddress"
                          placeholder="请输入公司地址"
                          required></b-form-input>
            <b-form-invalid-feedback v-if="submitted && !companyAddressState">公司地址为必填项</b-form-invalid-feedback>
          </b-form-group>

          <!-- 营业执照 -->
          <b-form-group label-for="license-upload"
                        label-cols-sm="2"
                        label-align-sm="right">
            <b-form-file id="license-upload"
                         accept="image/*"
                         placeholder="请上传公司营业执照"
                         @change="onFileChange"
                         required></b-form-file>
            <b-form-invalid-feedback v-if="submitted && !licenseState">营业执照为必填项</b-form-invalid-feedback>

            <!-- 图片预览 -->
            <b-img v-if="imagePreview"
                   :src="imagePreview"
                   fluid
                   alt="营业执照预览"
                   class="mt-3"
                   width="80" />
          </b-form-group>

          <!-- 测样说明 -->
          <b-form-group label-for="message-textarea"
                        label-cols-sm="2"
                        label-align-sm="right">
            <b-form-textarea id="message-textarea"
                             v-model="form.message"
                             placeholder="请输入测样说明"
                             rows="5"
                             max-rows="8"></b-form-textarea>
          </b-form-group>

          <!-- 提交按钮 -->
          <b-form-group label-cols-sm="2"
                        label-align-sm="right">
            <b-button type="submit"
                      variant="primary"
                      class="w-100">提交申请</b-button>
          </b-form-group>
        </b-form>
      </b-card>
    </b-container>
    <Footer></Footer>
  </div>
</template>
<script>
import Navbar from '../components/Navbar.vue';
import Footer from '../components/footer.vue';
import axios from 'axios'
export default {
  data() {
    return {
      form: {
        name: '',
        email: '',
        phone: '',
        companyName: '',
        companyAddress: '',
        license: null, // 保存上传的营业执照文件
        message: ''
      },
      imagePreview: null, // 预览营业执照图片
      submitted: false,
      nameState: null,
      emailState: null,
      phoneState: null,
      companyNameState: null,
      companyAddressState: null,
      licenseState: null
    };
  },
  components: {
    Navbar,
    Footer
  },
  methods: {
    onFileChange(event) {
      const file = event.target.files[0];
      if (file) {
        this.imagePreview = URL.createObjectURL(file); // 显示图片预览
        this.form.license = file; // 保存文件
      } else {
        this.imagePreview = null;
      }
    },
    onSubmit(event) {
      event.preventDefault();
      this.submitted = true;

      // 表单验证逻辑
      if (this.validateForm()) {
        const formData = new FormData();
        formData.append('name', this.form.name);
        formData.append('email', this.form.email);
        formData.append('phone', this.form.phone);
        formData.append('companyName', this.form.companyName);
        formData.append('companAddress', this.form.companyAddress);
        formData.append('file', this.form.license); // 上传的文件
        formData.append('message', this.form.message);

        // 发送表单数据
        axios.post('/api/images/upload', formData)
          .then(response => {
            alert('请耐心等待，我们将会通过邮件方式提醒您，请注意查看')
            console.log('提交成功', response.data);
          })
          .catch(error => {
            console.error('提交失败', error);
          });
      }
    },
    validateForm() {
      this.nameState = this.form.name ? true : false;
      this.emailState = this.form.email && this.isValidEmail(this.form.email);
      this.phoneState = this.form.phone ? true : false;
      this.companyNameState = this.form.companyName ? true : false;
      this.companyAddressState = this.form.companyAddress ? true : false;
      this.licenseState = this.form.license ? true : false;

      return this.nameState && this.emailState && this.phoneState && this.companyNameState && this.companyAddressState && this.licenseState;
    },
    isValidEmail(email) {
      const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return re.test(email);
    }
  }
};
</script>

<style scoped>
.custom-card {
  width: 70%; /* 设置卡片宽度为屏幕的70% */
  max-width: 1000px; /* 可选：限制最大宽度 */
  height: 95vh;
  background-color: transparent !important;
}

.b-button {
  font-size: 1.2rem;
}

.b-form-group {
  margin-bottom: 20px;
}

.b-container {
  height: 100vh; /* 满屏高度 */
}
</style>
