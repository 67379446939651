<template>
  <div class="data-page">
    <Navbar />
    
    <div class="data-content">
      <div v-if="isLoading && detailImage" class="loading-wrapper">
        <b-spinner variant="primary" label="Loading..."></b-spinner>
      </div>
      
      <div v-if="detailImage === ''" class="coming-soon">
        <h2 style="color: #3567E8;">🎨 创意还在进行中 ✨</h2>
        <p>详细介绍正在精心准备中</p>
        <p class="sub-text">敬请期待更多精彩内容～</p>
      </div>
      
      <img 
        v-if="detailImage !== ''"
        :src="`/static/${detailImage}`"
        alt="产品详情" 
        class="data-image"
        :class="{ 'image-loaded': !isLoading }"
        @load="handleImageLoaded"
        loading="lazy"
      >
    </div>

    <Footer/>
  </div>
</template>

<script>
import Navbar from '@/components/Navbar.vue'
import Footer from '@/components/footer.vue'

export default {
  name: 'ProductDetail',
  components: {
    Navbar,
    Footer
  },
  data() {
    return {
      isLoading: true,
      detailImage: this.$route.params.detailImage
    }
  },
  methods: {
    handleImageLoaded() {
      this.isLoading = false
    }
  }
}
</script>

<style scoped>
.data-page {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.data-content {
  flex: 1;
  width: 100%;
  position: relative;
  padding: 0;
  margin: 0;
}

.loading-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f5f5f5;
}

.data-image {
  width: 100%;
  height: auto;
  margin: 0;
  display: block;
  opacity: 0;
  transition: opacity 0.3s ease;
  object-fit: cover;
}

.image-loaded {
  opacity: 1;
}

.coming-soon {
  height: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #f8f9fa;
  border-radius: 8px;
  padding: 20px;
  text-align: center;
}

.coming-soon h2 {
  color: #6c63ff;
  margin-bottom: 16px;
  font-size: 24px;
}

.coming-soon p {
  color: #666;
  font-size: 16px;
}
</style>