<template>
  <div>
		<Navbar></Navbar>
    <!-- Banner Section -->
    <b-container fluid class="px-0 banner">
      <b-img src="business.png" alt="Banner Image" fluid></b-img>
    </b-container>

    <!-- 内容区域限制在1200px -->
    <div class="content-wrapper">
      <!-- Title Section -->
      <b-container class="my-4">
        <h1 class="text-center" style="margin-top: 100px; margin-bottom: 80px; font-weight: normal; font-size: 30px; color: #333;">招商加盟合作模式</h1>
        <h2 class="text-center cooperation-title">合作体系</h2>
      </b-container>

      <!-- Items Section -->
		<b-container>
    <b-row>
      <b-col cols="4" v-for="item in items" :key="item.id" class="item">
        <div class="item-content">
          <h3 class="item-title" style="font-size: 24px;">{{ item.title }}</h3>
          <div class="buttons">
            <b-button
              v-for="button in item.buttons"
              :key="button.text"
              :variant="button.variant"
              class="my-2 w-100 buttonss"
            >
              {{ button.text }}
            </b-button>
          </div>
        </div>
      </b-col>
    </b-row>
  </b-container>
    </div>

    <!-- Footer Section - 全宽度 -->
    <div class="footer-wrapper">
      <b-container fluid class="p-0">
        <b-row class="m-0">
          <b-col class="p-0">
            <b-img src="businessfooter.png" fluid class="footer-image"></b-img>
          </b-col>
        </b-row>
      </b-container>
    </div>
  </div>
</template>
<script>
import Navbar from '../components/Navbar.vue';
export default {
  data() {
    return {
      items: [
        { 
          id: 1, 
          title: "独家分销商",
          buttons: [
            { text: "详情", variant: "outline-primary" },
            { text: "申请", variant: "outline-primary" }
          ]
        },
        { 
          id: 2, 
          title: "品牌加盟",
          buttons: [
            { text: "查看更多", variant: "outline-primary" },
            { text: "注册", variant: "outline-primary" }
          ]
        },
        { 
          id: 3, 
          title: "C端业务",
          buttons: [
            { text: "获取信息", variant: "outline-primary" },
            { text: "联系我们", variant: "outline-primary" }
          ]
        }
      ]
    };
  },
	components: {
    Navbar,
  },
}
</script>
<style scoped>
/* 内容区域限制宽度 */
.content-wrapper {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 15px;
}

/* Banner 样式 */
.banner {
  width: 100%;
}

.banner img {
  width: 100%;
  height: 93vh;
	object-fit: cover;
}

.cooperation-title {
  background-color: #3567E8;
  color: white;
  border-top-left-radius: 34px;
  border-top-right-radius: 34px;
  padding: 10px;
  font-size: 36px;
  font-weight: normal;
}

.item {
  margin-top: 20px;
  position: relative;
}

.item-content {
  text-align: center;
  border: 1px solid #3567E8;
	border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  /* padding: 20px; */
	overflow: hidden;
  background-color: white;
	height: 300px;
}

.item-title {
  background-color: #3567E8;
  color: white;
	width: 100%;
	height: 80px;
	line-height: 80px;
  /* padding: 5px 0; */
}

.buttons {
  display: flex;
  flex-direction: column;
	width: 50%;
  margin-left: 25%;
  margin-top: 50px;
}

.item:hover .item-content {
  background-color: #3567E8;
}

.item:hover .item-title {
  color: white;
}

.item:hover .btn {
  background-color: white !important;
  color: #3567E8 !important;
  border-color: white !important;
}

.buttons .btn:hover {
  background-color: #3567E8 !important;
  color: white !important;
  border-color: white !important;
}

.footer-wrapper {
  width: 100%;
  background-color: #f5f5f5; /* 可选：设置背景色，防止图片加载时出现空白 */
  margin-top: 50px; /* 根据需要调整与上方内容的间距 */
}

.footer-image {
  width: 100vw; /* 使用视窗宽度 */
  height: auto;
  display: block;
  object-fit: cover;
  position: relative;
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
}

/* 移动端适配 */
@media (max-width: 768px) {
	.item-title{
		font-size: 16px;
	}
	.buttons {
    width: 100%;
    margin-left: 0;
    margin-top: 20px;
  }

  .buttonss {
    width: 80% !important; /* 按钮在移动端占满父容器的宽度 */
    white-space: nowrap; /* 禁止文字换行 */
    text-align: center; /* 文字居中 */
    font-size: 18px;
    margin: 10px 10px; /* 每个按钮之间的垂直间距 */
  }
	.fimg{
		padding: 0;
	}
  .footer-image {
    width: 100%;
    margin-left: 0;
    margin-right: 0;
    left: 0;
    right: 0;
  }
}
</style>
